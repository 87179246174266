import React from 'react';
import './App.module.scss';
import BaseLayout from "./components/BaseLayout";
import {BrowserRouter} from "react-router-dom";
import MessengerCustomerChat from 'react-messenger-customer-chat';

function App() {
   return (
      <div>
         <BrowserRouter>
            <BaseLayout/>
         </BrowserRouter>
         <MessengerCustomerChat pageId="100614865149161" appId="644550560564998"/>
      </div>
   );
}


export default App;
